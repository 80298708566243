import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

import OfferEmailForm from './OfferEmailForm';
//import Confirmation from './Confirmation';
import { setTitle, createModal, closeModal, simulation  } from '../../actions';
import RouterHelper from '../../helpers/RouterHelper';
import { currency } from '../../services/Format';
import ApiClient from '../../services/ApiClient';

class Offer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSend: false
    };

    this.period = [24, 36, 48, 60];
    this.renderOffer = this.renderOffer.bind(this);
    this.sendOffers = this.sendOffers.bind(this);
  }

  componentDidMount() {
    this.props.setTitle('Edition de l\'offre');
    if (!this.props.simulation) {
      this.props.history.push(RouterHelper.uriOf('simulation'));
    }
  }

  sendOffers({ recipients, comment, customer}) {
    const { user, simulation, period, scoring } = this.props;
    const company_name = user.getCompany().getName();
    const periodicity = simulation.periodicity;
    const total_amount = simulation.amount;
    const simulation_rent = simulation.rent;
    const type = simulation.type;

    const offers = [];

    this.period.forEach((key) => {
      if (period[key]) {
        offers.push({ duration: key, cost: scoring[key] });
      }
    });

    const sendingModal = {
      open: true,
      content: 'Votre demande est en cours d\'envoi',
      onClose: this.props.closeModal.bind(null, 'offer_success'),
    };

    this.props.createModal('sending_success', sendingModal);
    
    ApiClient.scoringOffer(recipients, company_name, periodicity === 'quarter' ? 'Trimestriel' : 'Mensuel', type, total_amount, simulation_rent, offers, comment, customer).then(() => {
      const modal = {
        open: true,
        content: 'Votre demande a été envoyée',
        onClose: () => {
          this.props.closeModal('offer_success');
          this.props.history.push(RouterHelper.uriOf('home'));
        },
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };
      
      this.props.closeModal('sending_success');
      this.props.createModal('offer_success', modal);
      this.props.setSimulation({});
      this.setState({isSend: true});
    }).catch(() => {
      const modal = {
        open: true,
        content: 'Une erreur est survenue',
        onClose: this.props.closeModal.bind(null, 'offer_error'),
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };

      this.props.closeModal('sending_success');
      this.props.createModal('offer_error', modal);
    });

  }

  renderOffer() {
    const { period, scoring, simulation} = this.props;
    const isAmount = simulation.amount;
    const periodicity = simulation.periodicity;

    return (
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Durée</Table.HeaderCell>
            <Table.HeaderCell>{isAmount ? 'Montant des loyers HT' : 'Montant Commande HT'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            function() {
              let rows = [];
              Object.keys(period).forEach((key) => {
                if (period[key]) {
                  rows.push(
                    <Table.Row key={key}>
                      <Table.Cell>{periodicity === 'quarter' ? key/3 : key} {periodicity === 'quarter' ? 'trimestres' : 'mois'}</Table.Cell>
                      <Table.Cell><span className="currency">{currency(Number(scoring[key]).toFixed(2))} €</span></Table.Cell>
                    </Table.Row>
                  );
                }
              });
              return rows;
            }()
          }

        </Table.Body>
      </Table>
    );
  }

  render() {
    const { simulation } = this.props;

    if (!simulation) {
      return <div></div>;
    }

    return (
      <div>
        <div>
          <h3>Récapitulatif des offres</h3>

          {this.renderOffer()}
          <h3>Envoyer les offres</h3>
          <OfferEmailForm onSubmit={this.sendOffers}/>

        </div>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
    simulation: state.app.simulation,
    period: state.app.period,
    scoring: state.app.scoring
  };
}

export default connect(mapStateToProps, { setTitle, createModal, closeModal, setSimulation: simulation })(Offer);
