import React from 'react';
import { TextArea as TextAreaUI } from 'semantic-ui-react';

export const Textarea = (props) => {
  let options = { ...props };
  delete options.label;
  delete options.meta;

  return (
    <TextAreaUI {...options} />
  );
};
