export function format(dateStr) {
  if (dateStr.substr(7, 1) !== '/') {
    return dateStr;
  }

  const date = new Date(dateStr);

  const day = ('0' + date.getDate()).substr(-2);
  const month = ('0' + (date.getMonth() + 1)).substr(-2);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatForApiValue(date) {
  let monthValue = date.getMonth() + 1;
  monthValue = (monthValue < 10 ? '0' : '') + monthValue;
  let dayValue = date.getDate() + 1;
  dayValue = (dayValue < 10 ? '0' : '') + dayValue;

  return `${date.getFullYear()}/${monthValue}/${dayValue} 00:00:00`;
}
