import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import RouterHelper, { Routes } from './helpers/RouterHelper';
import Sidenav from './components/sidenav';
import AppHeader from './components/app_header';
import Modal from './components/Modal';

import logo from './static/img/logo.jpg';

const muiTheme = getMuiTheme({
  fontFamily: 'PT sans, sans-serif',
  palette: {
    primary1Color: '#337ab7'
  }
});

class App extends Component {

  render() {
    return (
      <Router>
        <MuiThemeProvider muiTheme={muiTheme}>
          <div className="inner-root">
            <Sidenav />
            <div className="wrapper-main">
              <AppHeader />
              <div className="flex-container">
                  <footer className="footer-main text-center">
                    {RouterHelper.linkComponentOf(<img src={logo} className="logo" alt="Globalease" />, 'home')}
                  </footer>
                <div className="flex-child">
                  <div className="layout container">
                    {Routes}
                  </div>
                </div>
              </div>
            </div>
            <Modal/>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
