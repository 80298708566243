import React, { Component } from 'react';
import { Form as FormUI } from 'semantic-ui-react';

import { Field } from './Field';

function recursiveMap(children, cb) {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) { return child; }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, cb)
      });
    }

    return cb(child);
  });
}

export class Form extends Component {
  constructor(props) {
    super(props);
    const data = this.props.data || {};
    let touched = {};
    // const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];

    function setData(child) {
      const { name, type } = child.props;
      if (child.type === Field) {
        if (type === 'checkbox') {
          if (!data.hasOwnProperty(name)) {
            data[name] = {};
          }
          data[name][child.props.value] = false;
        } else {
          if (!data.hasOwnProperty(name)) {
            data[name] = '';
            touched[name] = false;
          }
        }
      }
    }

    recursiveMap(this.props.children, setData);

    this.state = { data: data, errors: null, touched: touched };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.linkField = this.linkField.bind(this);
  }

  validate(callback) {
    if (this.props.mode === 'props' && this.props.setData) {
      this.props.setData(this.state.data);
    }
    if (this.props.validate) {
      let errors = null;
      errors = this.props.validate(this.state.data);
      if (errors) {
        this.setState({errors}, callback);
      }
    } else {
      if (callback) {
        callback();
      }
    }
  };

  submit() {
    const errors = this.state.errors || {};

    if(Object.keys(errors).length === 0) {
      this.props.onSubmit(this.state.data);
      return;
    }
  }

  handleToggle(e, data) {
    const {name, value, checked} = data;

    this.setState(prevState => ({
      ...this.state,
      data: {
        ...prevState.data,
        [name]: {...prevState.data[name], [value]: checked},
      },
      touched: {
        ...prevState.touched,
        [name]: true,
      }
    }), this.validate);

  }

  handleInput(e, data) {
    const {name, value} = data;

    this.setState(prevState => ({
      ...this.state,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }), this.validate);
  }

  handleInputBlur(e) {
    const name = e.target.name;
    this.setState(prevState => ({
      ...this.state,
      touched: {
        ...prevState.touched,
        [name]: true,
      }
    }), this.validate);
  }

  handleSubmit(e) {
    let touched = this.state.touched;

    Object.keys(touched).forEach( key => {
      touched[key] = true;
    });
    this.setState({touched});

    this.validate(this.submit);
  }

  linkField(children) {
    children = React.Children.toArray(children);
    const mode = this.props.mode && this.props.mode === 'props' ? 'props' : 'state';
    return children.map((child)=> {
      let field = Object.assign({}, child);

      if (field && field.type === Field) {
        const name = field.props.name;
        const error = this.state.errors ? this.state.errors[name] ? this.state.errors[name] : '' : '';
        const touched = this.state.touched[name];

        field = {...field, props: {...field.props, meta: {error: error, touched: touched} }};
        if (field.props.type === 'checkbox') {
          field = { ...field, props: { ...field.props, checked: this[mode].data[name] && this[mode].data[name][field.props.value], onChange: (e, data) => this.handleToggle(e, data) }};
        } else {
          field = {...field, props: {...field.props, value: this[mode].data[name], onBlur: this.handleInputBlur, onChange: (e, data) => {
            if (child.props.onChange) {
              child.props.onChange(e, data);
            }
            this.handleInput(e, data);
          } }};
        }
      }

      return field;
    });
  };

  renderChildren() {
    return recursiveMap(this.props.children, this.linkField);
  }

  render() {
    let options = {...this.props };
    delete options.validate;
    delete options.setData;

    return (
      <FormUI {...options } onSubmit={(e) => { this.handleSubmit(e); }}>
        {this.renderChildren()}
      </FormUI>
    );
  }
};
