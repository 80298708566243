import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Message } from 'semantic-ui-react';

import RouterHelper from '../../helpers/RouterHelper';
import ApiClient from '../../services/ApiClient';
import Contact from '../../entities/Contact';
import Company from '../../entities/Company';
import { login, setTitle } from '../../actions';
import { Form, FormRow, Submit } from '../Form';
import { Field } from '../Form/Field';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      autoload: false,
      error: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  componentDidMount() {
    const identifier = localStorage.getItem('userIdentifier');
    const password = localStorage.getItem('userPassword');
    const isAuthenticated = this.props.isAuthenticated;

    this.props.setTitle('Connexion');

    if (identifier && password && !isAuthenticated) {
      const values = {
        identifier: identifier,
        password: password,
      };
      this.setState({autoload: true});
      this.onSubmit(values);
    }
  }

  componentWillUnmount() {
    this.setState({pending:false, autload:false});
  }

  validate(values) {
    const { identifier, password } = values;
    let errors = {};

    if (!identifier) {
      errors.identifier = 'Enter an identifier';
    }

    if (!password) {
      errors.password = 'Enter a password';
    }

    return errors;
  }

  onSubmit(values) {
    const { identifier, password } = values;

    this.setState({pending:true});

    ApiClient.signin(identifier, password).then(({data}) => {
      this.setState({pending:false, error: null});
      const {id, civility, lastname, firstname, login, email, partnerId, active, company} = data;
      const user = new Contact(id, civility, lastname, firstname, login, email, partnerId, active);
      const com = new Company(company.id, company.name, company.partnerId, company.email, company.webActive);
      user.setCompany(com);
      this.props.login(user);
    }).catch((error) => {
      console.error(error);
      const message = (error.response && error.response.data) || error;
      this.setState({ pending:false, error: message});
    });
  }

  renderError() {
    const { error } = this.state;

    if (!error) {
      return;
    }

    if (this.state.error === 'Authentification failed User company is not web active') {
      return (
        <Message warning visible>
          <p>Vous n'êtes pas autorisé à vous connecter par le web.</p>
        </Message>
      );
    } else if (this.state.error === 'Failed to authenticate user with provided login/password') {
      return (
        <Message warning visible>
          <p>Le couple login/mot de passe est erroné</p>
        </Message>
      );
    }
    return <Message warning visible>
        <p>Erreur de connexion</p>
      </Message>
  }

  render() {
    if (this.props.user.isAuthenticated) {
      return <Redirect to="/contrats"/>;
    }

    if (this.state.autoload) {
      return <div>Loading ...</div>;
    }

    return (
      <div className="home">
        <Form onSubmit={this.onSubmit.bind(this)} data={{identifier: '', password: ''}} validate={this.validate}>
          
          {this.renderError()}

          <Field
            label="Identifiant"
            name="identifier"
          />
          <Field
            label="Mot de passe"
            name="password"
            type="password"
          />

          <FormRow action>
            <Submit label='Envoyer' config={{loading:this.state.pending}} />
          </FormRow>

          <p className="text-center">
            <small>
              {RouterHelper.linkComponentOf('Mot de passe oublié ?', 'forgot_password')}
              &nbsp;&middot;&nbsp;
              {RouterHelper.linkComponentOf('Nouveau partenaire', 'signup')}
            </small>
          </p>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated
  };
};

const enhance = compose(
  connect(mapStateToProps, { login, setTitle }),
);

export default enhance(SignIn);
