import { CREATE_MODAL, CLOSE_MODAL } from '../actions';

const INITIAL_STATE = [];

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case CREATE_MODAL:
      let { id, modal } = action.payload;
      let isOpen = false;
      state.forEach((m) => {
        if (m.id === id) {
          isOpen = true;
        }
      });
      return isOpen ? state.map(function(m) {
        return m.id === id ? {id, modal} : m;
      }) : [...state, {id, modal}];
    case CLOSE_MODAL:
      const newState = [...INITIAL_STATE];
      state.forEach((m) => {
        if (m.id !== action.payload) {
          newState.push(m);
        }
      });
      return newState;
    default:
      return state;
  }
}
