import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openSidenav } from '../actions/';

import AppBar from 'material-ui/AppBar';

class AppHeader extends Component {
  render() {
    return (
      <AppBar
        className="header-wrapper"
        title={this.props.title}
        iconClassNameRight="muidocs-icon-navigation-expand-more"
        onLeftIconButtonTouchTap={this.props.openSidenav}
      />
    );
  }
};

function mapStateToProps(state) {
  return { title: state.app.title };
}

export default connect(mapStateToProps, { openSidenav, })(AppHeader);
