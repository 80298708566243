import React, { Component } from 'react';
//import { connect } from 'react-redux';
import { List, Button } from 'semantic-ui-react';
import { Form, Field, FormRow, Submit } from '../Form';

export const formConfig = {
  order: ['mail', 'comment'],
  fields: {
    customer: {
      label: 'Nom du client',
      name: 'customer',
      type: 'text'
    },
    comment: {
      label: 'Commentaire',
      name: 'comment',
      type: 'textarea'
    }
  }
};

class OfferEmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: "",
      recipients: [],
      data: {
      }
    };

    this.fields = formConfig.fields;

    this.addRecipient = this.addRecipient.bind(this);
    this.removeRecipient = this.removeRecipient.bind(this);
    this.renderField = this.renderField.bind(this);
    this.setData = this.setData.bind(this);
    this.onSubmit= this.onSubmit.bind(this);
  }

  renderField(key) {
    const config = this.fields[key];
    return <Field key={key} {...config} />;
  }

  addRecipient(email) {
    const recipients = this.state.recipients;

    if (email.length > 0 && !recipients.includes(email)) {
      recipients.push(email);
      this.setState({recipients});
    }
  }

  removeRecipient(email) {
    const recipients = this.state.recipients.filter(mail => mail !== email);
    this.setState({ recipients });
  }

  renderEmailList(recipients) {
    if (recipients.length > 0) {
      return (
        <div>
          <p>L'offre sera envoyée à :</p>
          <List divided relaxed verticalAlign='middle' className="file-list">
            {recipients.map((email) => {
              return (
                <List.Item key={email} >
                  <List.Icon name='mail' size='large' verticalAlign='middle' />
                  <List.Content className="file-label">
                    <List.Header>{email}</List.Header>
                  </List.Content>
                  <List.Content className="file-actions">
                    <Button onClick={this.removeRecipient.bind(this, email)} icon className="btn">
                      <List.Icon name='trash' />
                    </Button>
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </div>
      );
    } else {
      return <div className="ui info message">
        <div className="content">
          <p>Ajouter une adresse email</p>
        </div>
      </div>;
    }
  }

  setData(data) {
    this.setState({data});
  }

  onSubmit() {
    const { recipients, data } = this.state;
    this.props.onSubmit({
      recipients: recipients,
      comment : data.comment,
      customer: data.customer
    });
  }

  render() {
    const {recipients} = this.state;
    const submitIsDisabled = recipients.length === 0;
    const submitConfig = { loading: this.state.pending };
    return (
      <div>
        <Form mode="props" onSubmit={this.onSubmit.bind(this)} data={this.state.data} setData={this.setData}>
  
          <Field 
            label='Saisissez un email et appuyer sur +' 
            name='mail' 
            type='text' 
            action={{
              icon: 'plus',
              onClick: function(e) {
                e.preventDefault();
                e.stopPropagation();
                const {data} = this.state;
                this.addRecipient(data.mail);
                data.mail = '';
                this.setState({data});

                return false;
              }.bind(this) 
            }} />
          {this.renderEmailList(recipients)}

          {this.renderField('comment')}

          {this.renderField('customer')}

          <FormRow action>
            <Submit label='Envoyer' config={ submitIsDisabled ? {...submitConfig, disabled: true } : submitConfig } />
          </FormRow>

        </Form>
      </div>
    );
  }
}

export default OfferEmailForm;