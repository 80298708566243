import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Message, Table } from 'semantic-ui-react';

import { setTitle, createModal, closeModal, scoringPdf, scoringOnline } from '../../actions';
import RouterHelper from '../../helpers/RouterHelper';
import { Form, FormRow, Field } from '../Form';
import { currency } from '../../services/Format';
import { FinancingCoeff } from '../../services/FinancingSimulatorManager';

class Scoring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amountMin: null,
      amountMax: null,
      error: null,
      type: null,
      scoring: {
        24: null,
        36: null,
        48: null,
        60: null,
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setTitle('Simulation');
    if (!this.props.simulation) {
      this.props.history.push(RouterHelper.uriOf('simulation'));
    }

    const {simulation, parameters} = this.props;

    if(!simulation) {
      return;
    }

    const { type, periodicity, amount } = simulation;
    const financing = simulation.amount ? simulation.amount : simulation.rent;
    const period = [24,36,48,60];
    const divider = periodicity === 'month' ? 1 : 3;

    function computeAmount(coeff) {
      return financing * coeff / 100;
    }

    function computeRent(coeff, type, periodicity, during) {
      const val = simulator.rentIsInArray(financing, type, periodicity, during);
      if (val) {
        return val;
      }
      return financing * 100 / coeff;
    }

    const simulator = new FinancingCoeff(parameters.amount, parameters.rent, parameters.partMin, parameters.partMax);
    let scoring = {};

    const amountMin = simulator.getFinancingAmountMin();
    const amountMax = simulator.getFinancingAmountMax();

    this.setState({
      amountMin,
      amountMax
    })

    const data = period.map((p) => {
      return simulator.getCoeff(financing, amount ? 'amount' : 'rent', type.substr(0,3), periodicity, p/divider);
    });

    data.forEach((coeff, index) => {
      scoring[period[index]] = Number( 
        amount ?
          computeAmount(coeff) :
          computeRent(coeff, type.substr(0, 3), periodicity, period[index] / divider, this.simulator)
      ).toFixed(2);
    });

    this.setState({ scoring });
  }

  countChoice(values = {}) {
    let count = 0;
    Object.keys(values).forEach(function (key) {
      if (values[key]) {
        count++;
      }
    });

    return count;
  }

  onSubmit(values) {
    const time = {...values.time};
    const count = this.countChoice(time);
    const { createModal, scoringPdf, scoringOnline } = this.props;
    const scoring = this.state.scoring;

    if (count === 0) {
      const modal = {
        open: true,
        content:'Vous devez sélectionner un choix avant de continuer',
        onClose: this.props.closeModal.bind(null, 'scoring'),
        actions:[
          { key: 'done', content: 'Fermer' },
        ]
      };

      createModal('scoring', modal);
      return;
    }

    Object.keys(time).forEach(element => {
      if (time[element]) {
        time[element] = scoring[element];
      }
    });

    const data = {...values, time};

    if (this.state.type === 'scoring') {
      if (count > 1) {
        const modal = {
          open: true,
          content:'Vous ne pouvez sélectionner qu\'une seule durée',
          onClose: this.props.closeModal.bind(null, 'scoring'),
          actions:[
            { key: 'done', content: 'Fermer' },
          ]
        };

        createModal('scoring', modal);
        return;
      } else {
        scoringOnline(data);
        this.props.history.push(RouterHelper.uriOf('scoring_online'));
      }
    } else {
      scoringPdf({values: data, scoring});
      this.props.history.push(RouterHelper.uriOf('offer'));
    }
  }

  handleButtonSubmit(type) {
    this.setState({type: type});
    //this.refs.form.submit();
  }

  renderError() {
    if (this.state.error === 'User company is not web active') {
      return (
        <Message warning visible>
          <p>Vous n'êtes pas autorisé à vous connecter par le web.</p>
        </Message>
      );
    } else if (this.state.error === 'Failed to authenticate user with provided login/password') {
      return (
        <Message warning visible>
          <p>Erreur de connexion</p>
        </Message>
      );
    }
    return <div></div>;
  }
  getType(type) {
    switch (type) {
      case 'mat':
        return '+ 50% Hardware';
      case 'log1':
        return '- 50% Hardware';
      case 'log2':
      default:
        return '100% Software';
    }
  }

  getAmount(simulation) {
    if (simulation.amount) {
      return <span>
        Montant : <span className="currency">{currency(Number(simulation.amount))} €</span>
      </span>;
    } else {
      return <span>
        Loyer : <span className="currency">{currency(Number(simulation.rent))} €</span>
      </span>;
    }
  }

  render() {
    const { simulation  } = this.props;
    const { amountMin, amountMax } = this.state;
    const isAmount = simulation.amount;
    const periodicity = simulation.periodicity;
    let nbOffer = 0;

    return (
      <Form onSubmit={this.onSubmit} ref="form">
        <h3>Simulation</h3>

        {this.renderError()}

        <p>Type de projet : {this.getType(simulation.type)}<br/>
        Coût du loyer exprimé en : {simulation.periodicity === 'quarter' ? 'Trimestriel' : 'Mensuel'}<br/>
        Paiement trimestriel, terme à échoir<br/>
        {this.getAmount(simulation)}
        </p>

        <Table unstackable className="scoring">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Durée</Table.HeaderCell>
              <Table.HeaderCell>
                {!isAmount && <span>Montant de la commande</span>}
                {isAmount && 
                  <span>Montant du loyer<span style={{ fontWeight: 400, fontSize: '0.8em' }}>/ {periodicity === 'quarter' ? 'trimestre' : 'mois'}</span></span>
                }
              </Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {[24,36,48,60].map((key) => {
              if (isAmount || (!isAmount && amountMin < this.state.scoring[key] && this.state.scoring[key] < amountMax)) {
                nbOffer++;
                return (
                  <Table.Row key={key}>
                    <Table.Cell>{periodicity === 'quarter' ? key / 3 : key}  {periodicity === 'quarter' ? 'trimestres' : 'mois'}</Table.Cell>
                    <Table.Cell>
                      {
                        this.state.scoring[key] && <span className="currency">
                          {
                            currency(Number(this.state.scoring[key]).toFixed(2))
                          }
                        </span>
                      }
                    </Table.Cell>
                    <Table.Cell verticalAlign="middle">

                      <Field
                        name="time"
                        type="checkbox"
                        value={key}
                        style={{ display: this.state.scoring[key] ? 'block' : 'none' }}
                        layout="icon"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              } else {
                return null;
              }
            })}
          </Table.Body>
        </Table>

        {nbOffer === 0 && <p>
          Nous n'avons aucune offre à proposer
        </p>}

        <FormRow action>
          {simulation.amount && <Button onClick={this.handleButtonSubmit.bind(this, 'editer')} primary size='big'>Editer</Button>}
          <Button onClick={this.handleButtonSubmit.bind(this, 'scoring')} primary size='big'>Etude / Scoring en ligne</Button>
        </FormRow>

      </Form>
    );
  }
};

function mapStateToProps(state) {
  return {
    simulation: state.app.simulation,
    parameters: state.app.parameters,
  };
}

export default connect(mapStateToProps, { setTitle, createModal, closeModal, scoringPdf, scoringOnline })(Scoring);
