import React from 'react';
import { Form } from 'semantic-ui-react';

export const FormRow = (props) => {
  const { touched, error } = props;

  return (
    <Form.Field error={error && touched ? true : false} className={props.action ? 'form-action' : ''}>
      {props.label && <label>{props.label}</label>}
      {props.children}
    </Form.Field>
  );
};
