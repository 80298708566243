import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import RouterHelper from '../../helpers/RouterHelper';
import { setTitle, createModal, closeModal  } from '../../actions';
import { Form, FormRow, Field, Submit } from '../Form';
import ApiClient from '../../services/ApiClient';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setTitle('Inscription');
  }

  validate(values) {
    const { company_name, siret_number, lastname, firstname, email, phone } = values;
    let errors = {};

    if (!company_name) {
      errors.company_name = 'Le nom de la société est obligatoire';
    }

    if (!siret_number) {
      errors.siret_number = 'Le numéro de SIRET est obligatoire';
    }

    if (!lastname) {
      errors.lastname = 'Le nom de contact est obligatoire';
    }

    if (!firstname) {
      errors.firstname = 'Le prénom du contact est obligatoire';
    }

    if (!email) {
      errors.email = 'L\'email est obligatoire';
    } else if (!isEmail(email)) {
      errors.email = 'L\'email n\'est pas valide';
    }

    if (!phone) {
      //validators={['required', 'matchRegexp:^[0-9]{10}$']}
      errors.phone = 'Le numéro de téléphone est obligatoire';
    } else if (!/^[0-9]{10}$/.exec(phone)) {
      errors.phone = 'Le numéro de téléphone n\'est pas valide';
    }

    return errors;
  }

  onSubmit({ company_name, siret_number, lastname, firstname, email, phone }) {
    this.setState({ pending: true });
    ApiClient.signup(company_name, siret_number, lastname, firstname, email, phone).then(() => {
      const modal = {
        open: true,
        content: 'Votre demande a été envoyée',
        onClose: () => {
          this.props.closeModal('signup_success');
          this.props.history.push(RouterHelper.uriOf('home'));
        },
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };

      this.props.createModal('signup_success', modal);
    }).catch(() => {
      const modal = {
        open: true,
        content: 'Une erreur est survenue',
        onClose: this.props.closeModal.bind(null, 'signup_error'),
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };

      this.props.createModal('signup_error', modal);
    })
    .finally(() => this.setState({ pending: false }));
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit} validate={this.validate}>
        <h1 className="text-center">Nouveau partenaire</h1>
        <Field
          label="Nom de la société"
          name="company_name"
        />
        <Field
          label="Numéro de SIRET"
          name="siret_number"
        />

        <Field
          label="Nom du contact"
          name="lastname"
        />

        <Field
          label="Prénom du contact"
          name="firstname"
        />

        <Field
          label="Email"
          name="email"
        />

        <Field
          label="Numéro de téléphone"
          name="phone"
        />

        <FormRow action>
          <Submit label='Envoyer' config={{ loading: this.state.pending }} />
        </FormRow>

        <p className="text-center">
          <small>
            {RouterHelper.linkComponentOf('Vous êtes déjà partenaire ?', 'home')}
          </small>
        </p>
      </Form>
    );
  }
}

export default connect(null, { setTitle, createModal, closeModal  })(SignUp);
