import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import RouterHelper from '../../helpers/RouterHelper';
import { setTitle, createModal, closeModal } from '../../actions';
import { Form, FormRow, Field, Submit } from '../Form';
import ApiClient from '../../services/ApiClient';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setTitle('Récupération de mot de passe');
  }

  validate(values) {
    const { identifier, email, company } = values;
    let errors = {};

    if (!identifier) {
      errors.identifier = 'Identifiant olbigatoire';
    }

    if (!company) {
      errors.company = 'Identifiant olbigatoire';
    }

    if (!email) {
      errors.email = 'L\'email est obligatoire';
    } else if (!isEmail(email)) {
      errors.email = 'L\'email n\'est pas valide';
    }

    return errors;
  }

  onSubmit({identifier, email, company}) {
    this.setState({pending: true});
    ApiClient.password(identifier, email, company).then(() => {
      const modal = {
        open: true,
        content: 'Votre demande a été envoyée',
        onClose: () => {
          console.log('on close');
          this.props.closeModal('password_success');
          this.props.history.push(RouterHelper.uriOf('home'));
        },
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };
      this.setState({pending: false});
      this.props.createModal('password_success', modal);
    }).catch(() => {
      const modal = {
        open: true,
        content: 'Une erreur est survenue',
        onClose: this.props.closeModal.bind(null, 'password_error'),
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };
      this.setState({pending: false});
      this.props.createModal('password_error', modal);
    })
    .finally(() => this.setState({ pending: false }));
  }

  render() {
    return (
      <div className="home">
        <Form onSubmit={this.onSubmit} validate={this.validate}>
          <h1 className="text-center">Mot de passe oublié</h1>
          <Field
            label="Identifiant"
            name="identifier"
          />
          <Field
            label="Société"
            name="company"
          />
          <Field
            label="Email"
            name="email"
          />
          <FormRow action>
            <Submit label='Envoyer' config={{loading:this.state.pending}} />
          </FormRow>

          <p className="text-center">
            <small>
              {RouterHelper.linkComponentOf('Nouveau partenaire', 'signup')}
            </small>
          </p>
        </Form>
      </div>
    );
  }
}

export default connect(null, { setTitle, createModal, closeModal })(ForgotPassword);

