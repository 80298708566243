import React from 'react';
import { Input as InputUI } from 'semantic-ui-react';

export const Input = (props) => {
  let options = {...props};
  delete options.label;
  delete options.meta;
  options.error = options.error ? true : false;

  return (
    <InputUI fluid {...options} />
  );
};
