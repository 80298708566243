import { 
  OPEN_SIDENAV, 
  CLOSE_SIDENAV, 
  SET_TITLE_APPBAR, 
  SIMULATION, 
  SCORING_PDF, 
  SCORING_ONLINE,
  SET_PARAMETERS
} from '../actions';

const INITIAL_STATE = {
  sidenavOpened: false,
  parameters: null,
  title: '', 
  simulation: null, 
  period: null, 
  scoring: null
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case SET_PARAMETERS:
      return {...state, parameters: action.payload}
    case SET_TITLE_APPBAR:
      return {...state, title: action.payload};
    case OPEN_SIDENAV:
      return {...state, sidenavOpened: true };
    case CLOSE_SIDENAV:
      return {...state, sidenavOpened: false };
    case SIMULATION:
      return {...state, simulation: action.payload};
    case SCORING_PDF:
      return {...state, period: action.payload.values.time, scoring: action.payload.scoring};
    case SCORING_ONLINE:
      return {...state, scoring: action.payload};
    default:
      return state;
  }
}
 