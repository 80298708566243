
export default class Contact {
  /**
   * Constructor.
   *
   * @param  int    id
   * @param  string lastname
   * @param  string firstname
   * @param  string login
   * @param  string partnerId
   * @param  bool   active
   */
  constructor(id, civility, lastname, firstname, login, email, partnerId, active) {
    this.id = id;
    this.civility = civility;
    this.lastname = lastname;
    this.firstname = firstname;
    this.login = login;
    this.partnerId = partnerId;
    this.active = active;
    this.company = null;
    this.email = email;
  }

  /**
   * Returns contact id.
   *
   * @return int
   */
  getId() {
    return this.id;
  }

  /**
   * Returns contact civility
   *
   * @return string
   */
  getCivility() {
    return this.civility;
  }

  /**
   * Returns contact lastname.
   *
   * @return string
   */
  getLastname() {
    return this.lastname;
  }

  /**
   * Returns contact firstname.
   *
   * @return string
   */
  getFirstname() {
    return this.firstname;
  }

  /**
   * Returns contact login.
   *
   * @return string
   */
  getLogin() {
    return this.login;
  }

  /**
   * Returns contact partner id.
   *
   * @return string
   */
  getPartnerId() {
    return this.partnerId;
  }

  /**
   * Returns true if contact is active, else false.
   *
   * @return bool
   */
  isActive() {
    return this.active;
  }

  /**
   * Returns contact associated company or null.
   *
   * @return null|Company
   */
  getCompany() {
    return this.company;
  }

  /**
   * Returns contact email
   *
   * @return string
   */
  getEmail() {
    return this.email;
  }

  /**
   * Sets company (instance of Company entity) for contact.
   *
   * @param Company company
   */
  setCompany(company) {
    this.company = company;
  }
}
