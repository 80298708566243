import React, { Component } from 'react';
import { connect } from 'react-redux';

import SignIn from './screens/SignIn';
import ApiClient from '../services/ApiClient';
import Contact from '../entities/Contact';
import Company from '../entities/Company';
import { login } from '../actions';

export default function(ComposedComponent) {
  class Authentication extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pending: false,
      }
    }

    componentDidMount() {
      this.setState({pending: true});
      ApiClient.isAuthenticated().then(({data}) => {
        this.setState({pending: false});
        const {id, civility, lastname, firstname, login, email, partnerId, active, company} = data;
        const user = new Contact(id, civility, lastname, firstname, login, email, partnerId, active);
        const com = new Company(company.id, company.name, company.partnerId, company.email, company.webActive);
        user.setCompany(com);
        this.props.login(user);
      }).catch(() => {
        this.setState({pending: false});
      })
    }

    render() {
      const { pending } = this.state;

      if (pending) {
        return <div>Loading</div>;
      }

      if (this.props.user && this.props.user.isAuthenticated) {
        return <ComposedComponent {...this.props}/>;
      }

      return <SignIn {...this.props}/>;
    }
  }

  function mapStateToProps(state) {
    return { user: state.user };
  }

  return connect(mapStateToProps, { login })(Authentication);
}
