import React from 'react';
import { Radio as RadioUI } from 'semantic-ui-react';

export const Radio = (props) => {
  let options = {...props};
  const isChecked = options.value === options.data;
  delete options.meta;
  delete options.options;
  delete options.layout;

  return (
    <span style={{display: 'block'}}>
      <RadioUI {...options} checked={isChecked} />
    </span>
  );
};
