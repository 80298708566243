export const OPEN_SIDENAV = 'OPEN_SIDENAV';
export const CLOSE_SIDENAV = 'CLOSE_SIDENAV';
export const SET_TITLE_APPBAR = 'SET_TITLE_APPBAR';
export const SIMULATION = 'SIMULATION';
export const SCORING_PDF = 'SCORING_PDF';
export const SCORING_ONLINE = 'SCORING_ONLINE';
export const ADD_RECIPIENT = 'ADD_RECIPIENT';
export const DELETE_RECIPIENT = 'DELETE_RECIPIENT';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_PARAMETERS = 'SET_PARAMETERS';

export function setTitle(title) {
  return {
    type: SET_TITLE_APPBAR,
    payload: title
  };
}

export function closeSidenav() {
  return {
    type: CLOSE_SIDENAV
  };
}

export function openSidenav() {
  return {
    type: OPEN_SIDENAV
  };
}

export function simulation(values) {
  return {
    type: SIMULATION,
    payload: values
  };
}

export function scoringPdf(values) {
  return {
    type: SCORING_PDF,
    payload: values,
  };
}

export function scoringOnline(values) {
  return {
    type: SCORING_ONLINE,
    payload: values,
  };
}

export function addRecipient(email) {
  return {
    type: ADD_RECIPIENT,
    payload: email,
  };
}

export function deleteRecipient(email) {
  return {
    type: DELETE_RECIPIENT,
    payload: email,
  };
}

export function setComment(comment) {
  return {
    type: SET_COMMENT,
    payload: comment,
  };
}

export function setParameters(params) {
  return {
    type: SET_PARAMETERS,
    payload: params
  }
}