import { SIGNIN, AUTHENTICATION } from '../actions';

export default function ({dispatch}) {
  return next => action => {

    if (action.type === SIGNIN && action.payload.data && action.payload.data.ResultInfos.Success) {

      // localStorage.setItem('token', action.payload.data.ResultData.Token);
      // localStorage.setItem('userLogin', action.meta.login);

      dispatch({type: AUTHENTICATION});
    }

    return next(action);
  };
}
