
export default class Company {
  /**
   * Constructor.
   *
   * @param  int    id
   * @param  string name
   * @param  string partnerId
   * @param  bool   webActive
   */
  constructor(id, name, partnerId, email, webActive) {
    this.id = id;
    this.name = name;
    this.partnerId = partnerId;
    this.email = email;
    this.webActive = webActive;
  }

  /**
   * Returns company id.
   *
   * @return int
   */
  getId() {
    return this.id;
  }

  /**
   * Returns company name
   *
   * @return string
   */
  getName() {
    return this.name;
  }

  /**
   * Returns company partner id.
   *
   * @return string
   */
  getPartnerId() {
    return this.partnerId;
  }

  /**
   * Returns company email.
   *
   * @return string
   */
  getEmail() {
    return this.email;
  }

  /**
   * Returns true if company is active for web, else false.
   *
   * @return bool
   */
  isWebActive() {
    return this.webActive;
  }
}
