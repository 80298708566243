import React, { Component} from 'react';
import { Progress, Button } from 'semantic-ui-react';
import RouterHelper from '../../helpers/RouterHelper';

class ConfirmationOnline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percent: 0
    };
  }

  componentDidMount() {
    console.log('set state')
    setTimeout(() => {
      this.setState({percent: 100});
    }, 300);
  }

  render() {
    const { percent } = this.state;
    return (<div>
      <Progress className="confirmation" percent={percent} color='blue' size='medium'>
        Votre dossier a été envoyé
      </Progress>
      {RouterHelper.linkComponentOf(<Button content="Retour à l'accueil" fluid size='big' />, 'home')}
    </div>
    );
  }
}

export default ConfirmationOnline;