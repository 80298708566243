import React from 'react';
import { Input as InputUI } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';

export const File = (props) => {
  let options = { ...props };
  delete options.button;
  delete options.label;
  delete options.meta;
  options.error = options.error ? true : false;

  return (
    <label>
      <Button as="span" {...props.button}/>
      <InputUI fluid {...options} style={{display:'none'}}/>
    </label>
  );
};
