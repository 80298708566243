import React, { Component } from 'react';
import { Checkbox as CheckboxUI, Icon } from 'semantic-ui-react';

export class Checkbox extends Component {
  state = { checked: false }
  toggle = (e, data) => {
    const newState = !this.state.checked;
    if (this.props.onChange) {
      const {name, value} = this.props;

      this.props.onChange(e, {
        name,
        value,
        checked: newState
      });
    }
    this.setState({ checked: newState });
  }

  render() {
    const props = this.props;
    let options = { ...props };
    let layout = props.layout;
    delete options.meta;
    delete options.options;
    delete options.layout;
    options.checked = options.checked === true;

    const name = `${this.state.checked ? 'check ' : ''}square outline`;
    const style = layout === 'icon' ? {
      position: 'absolute',
      height: 0,
      width: 0,
      zIndex: -1
    } : {};

    return (
      <span style={{ display: 'block' }}>
        <CheckboxUI {...options} onChange={this.toggle} checked={this.state.checked} style={style} />
        {layout === 'icon' && <Icon name={name} onClick={this.toggle} />}
      </span>
    );
  }
}
