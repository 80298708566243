import { combineReducers } from 'redux';
import UserReducer from './reducer_user';
import ModalsReducer from './reducer_modals';
import AppReducer from './reducer_app';

const rootReducer = combineReducers({
  user: UserReducer,
  modals: ModalsReducer,
  app: AppReducer,
  form: null
});

export default rootReducer;
