import React from 'react';
import { Button, Icon, Radio } from 'semantic-ui-react';

export const ButtonRadio = (props) => {
  let options = { ...props };
  const isChecked = options.value === options.data;
  delete options.meta;
  delete options.options;
  delete options.layout;
  //options.checked = options.checked === true;
  const icon = isChecked ? 'check circle outline' : 'circle outline';

  return (
    <Button icon labelPosition='left' as="label">
      <Icon name={icon} />
      {options.label}
      <Radio {...options} checked={isChecked} style={{opacity:0, position: 'absolute', zIndex: -1, width: '10px', overflow: 'hidden'}} />
    </Button>
  );
};

