import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import requireAuth from '../components/require_authentication';

import Home from '../components/screens/Home';
import Simulation from '../components/screens/Simulation';
import SignUp from '../components/screens/SignUp';
import Contract from '../components/screens/Contract';
import ForgotPassword from '../components/screens/ForgotPassword';
import Offer from '../components/screens/Offer';
import Scoring from '../components/screens/Scoring';
import ScoringOnline from '../components/screens/ScoringOnline';
import NoMatch from '../components/screens/NoMatch';

const routes = {
  home: '/',
  signup: '/inscription',
  forgot_password: '/mot-de-passe-oublie',
  simulation: '/simulation',
  listing: '/contrats',
  scoring: '/scoring',
  scoring_online: '/scoring-online',
  offer: '/offer',
};

class RouterHelper {
  patternOf(name) {
    return routes.hasOwnProperty(name) && routes[name];
  }

  uriOf(name, params = []) {
    let pattern = this.patternOf(name);
    if (!pattern || 0 === params.length) {
      return pattern;
    }

    for (let key in params) {
      pattern = pattern.replace(`:${key}`, params[key]);
    }

    return pattern;
  }

  linkComponentOf(content, name, params = []) {
    const uri = this.uriOf(name, params);

    return uri && <Link to={uri}>{content}</Link>;
  }
}

const helper = new RouterHelper();
export default helper;

export const Routes = (
  <Switch>
    <Route exact path={helper.uriOf('home')} component={requireAuth(Home)} />

    <Route path={helper.uriOf('signup')} component={SignUp} />
    <Route path={helper.uriOf('forgot_password')} component={ForgotPassword} />
    <Route path={helper.uriOf('simulation')} component={requireAuth(Simulation)} />
    <Route path={helper.uriOf('listing')} component={requireAuth(Contract)} />} />
    <Route path={helper.uriOf('scoring_online')} component={requireAuth(ScoringOnline)} />} />
    <Route path={helper.uriOf('scoring')} component={requireAuth(Scoring)} />} />
    <Route path={helper.uriOf('offer')} component={requireAuth(Offer)} />} />
    <Route component={NoMatch}/>
  </Switch>
);
