import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Message,
  Button,
  Icon,
  Table,
  Popup
} from 'semantic-ui-react';

import {
  setTitle,
  createModal,
  closeModal
  // fetchContracts
} from '../../actions';
import { format } from '../../helpers/dateHelper';
import ApiClient from '../../services/ApiClient';

class Contracts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      contracts: null,
      error: null,
      isOpen: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.props.setTitle('Suivi des demandes');
    
    this.setState({pending: true});
    ApiClient.contracts().then(({data}) => {
      this.setState({contracts: data, error: null});
    }).catch((error) => {
      this.setState({error: error.message || error});
    }).then(() => {
      this.setState({pending: false})
    });
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  renderContracts() {
    const { pending } = this.state;
    const contracts = [];
    let header = null;

    if (pending) {
      return <Icon loading name='circle notched' />
    }

    if (!this.state.contracts) {
      return <div>Aucun contrat</div>
    }

    this.state.contracts.forEach((contract, index) => {
      let date = contract.da_date ? contract.da_date : contract.date;
      date = date ? format(date) : '-';

      const modal = {
        open: true,
        content: contract.reponse_web || 'Pas de commentaire',
        onClose: this.props.closeModal.bind(null, 'contract_info'),
        actions: [
          { key: 'done', content: 'Fermer' },
        ]
      };

      contracts.push(
        <Table.Row verticalAlign='top' key={index}>
          <Table.Cell>{contract.nom_web}{contract.nom_web ? <br/> : ''}{contract.da_number}</Table.Cell>
          <Table.Cell>{date}<br />{contract.da_amount}{contract.da_amount ? '€' : ''}</Table.Cell>
          <Table.Cell>{contract.status}</Table.Cell>
          <Table.Cell>
            {contract.status !== 'En cours' && <Button compact onClick={() => {
              this.props.createModal('contract_info', modal);
            }}>Détail</Button>}
          </Table.Cell>
        </Table.Row>
      );
    });

    header = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Références</Table.HeaderCell>
          <Table.HeaderCell>Montant</Table.HeaderCell>
          <Table.HeaderCell>État</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );

    return (
      <Table compact>
        {document.body.clientWidth > 767 && header}
        <Table.Body>
          {contracts}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { error } = this.state;
    return (
      <div>
        <h3>Suivi des demandes</h3>
        <Popup
          trigger={<Button style={{float: 'right', marginBottom: '1em', marginTop: -46 }} icon><Icon name='info' /></Button>}
          on='click'
          open={this.state.isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          position='bottom right'
          wide
        >
          Nom du client<br/>
          Numéro d’agrément<br />
          Date de la demande<br />
          Montant HT<br />
          État
        </Popup>
        { error && <Message warning visible>
          <p>Erreur de connexion</p>
        </Message>
        }
        {this.renderContracts()}
      </div>
    );
  }
};

export default connect(null, { setTitle, createModal, closeModal })(Contracts);
