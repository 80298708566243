import axios from 'axios';
import { store } from '../index';
import { signout } from '../actions'

axios.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  store.dispatch(signout());
  return Promise.reject(error);
});

/**
 * API base URL endpoint.
 *
 * @type string
 */
const API_URL = '/api';

const ROUTES = {
  signin: '/signin',
  isAuthenticated: '/check-user',
  signout: '/signout',
  contracts: '/contracts',
  simulationParameters: '/simulation-parameters',
  financingRequest: '/financing-request',
  scoringOffer: '/scoring-offer',
  scoringFile: '/scoring-file',
  signup: '/signup',
  password: '/forgot-password',
};

export default {
  signin: (identifier, password) => {
    return axios.post(`${API_URL}${ROUTES.signin}`, {
      identifier,
      password
    }, {
      withCredentials: true
    });
  },

  isAuthenticated: () => {
    return axios.get(`${API_URL}${ROUTES.isAuthenticated}`);
  },
  
  logout: () => {
    return axios.get(`${API_URL}${ROUTES.signout}`);
  },
  
  contracts: () => {
    return axios.get(`${API_URL}${ROUTES.contracts}`);
  },

  financingRequest: (amount, date, siret_siren, customer) => {
    return axios.post(`${API_URL}${ROUTES.financingRequest}`, {
      amount,
      date,
      siret_siren,
      customer,
    });
  },
  
  simulationParameters: () => {
    return axios.get(`${API_URL}${ROUTES.simulationParameters}`);
  },

  scoringOffer: (recipients, company_name, periodicity, type, total_amount, simulation_rent, offers, comment, customer) => {
    return axios.post(`${API_URL}${ROUTES.scoringOffer}`, {
      recipients,
      company_name,
      periodicity,
      type,
      total_amount,
      simulation_rent,
      offers,
      comment,
      customer,
    });
  },

  scoringFile: (daNumber, simulation, nbFiles, storageUniqueKey, rentAmount, rentTime) => {
    return axios.post(`${API_URL}${ROUTES.scoringFile}`, {
      daNumber,
      simulation,
      nbFiles,
      storageUniqueKey,
      rentAmount,
      rentTime
    });
  },

  signup: (company_name, siret_number, lastname, firstname, email, phone) => {
    return axios.post(`${API_URL}${ROUTES.signup}`, {
      company_name,
      siret_number,
      lastname,
      firstname,
      email,
      phone
    });
  },

  password: (identifiant, email, company) => {
    return axios.post(`${API_URL}${ROUTES.password}`, {
      identifiant,
      email,
      company,
    })
  }
}

