export const AUTHENTICATION = 'AUTHENTICATION';
export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';

export function setToken(token) {
  return {
    type: AUTHENTICATION,
    payload: token,
  };
}

export function login(user) {
  return {
    type: SIGNIN,
    payload: user,
  };
}

export function signout() {
  return {
    type: SIGNOUT,
  };
}
