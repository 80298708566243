export const CREATE_MODAL = 'CREATE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export function createModal(id, modal) {
  return {
    type: CREATE_MODAL,
    payload: {id, modal},
  };
}

export function closeModal(id) {
  return {
    type: CLOSE_MODAL,
    payload: id,
  };
}
