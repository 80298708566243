import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTitle, simulation } from '../../actions';
import RouterHelper from '../../helpers/RouterHelper';
import { FinancingCoeff } from '../../services/FinancingSimulatorManager';
import ApiClient from '../../services/ApiClient';
import { setParameters } from '../../actions';

import { Form, FormRow, Field, Submit } from '../Form';

export const formConfig = {
  order: ['type', 'periodicity', 'amount', 'rent'],
  fields: {
    type: {
      label: 'Type de projet',
      name: 'type',
      type: 'radio',
      layout: 'button',
      options: [{ label: '+ 50% Hardware ', value: 'mat' }, { label: '- 50% Hardware ', value: 'log1' }, { label: '100% Software ', value: 'log2' }]
    },
    periodicity: {
      label: 'Coût du loyer exprimé en',
      name: 'periodicity',
      type: 'radio',
      layout: 'button',
      options: [
        { label: 'Trimestriel', value: 'quarter' },
        { label: 'Mensuel', value: 'month' }
      ]
    },
    amount: {
      label: 'Montant à financer H.T.',
      name: 'amount',
      pattern: '[0-9]+([.,][0-9]+)?',
      type: 'number',
      step: '0.01',
      inputMode: 'numeric'
    },
    rent: {
      paragraph: 'Ou',
      label: 'Loyer H.T.',
      name: 'rent',
      type: 'number',
      pattern: '[0-9]+([.,][0-9]+)?',
      step: '0.01',
      inputMode: 'numeric'
    }
  }
};

export const formValidation = (values, simulator) => {
  const { type, periodicity, amount, rent } = values;
  let errors = {};

  if (!type) {
    errors.type = 'Choisir un type de projet';
  }

  if (!periodicity) {
    errors.periodicity = 'Choisir une périodicité';
  }

  if (!amount && !rent) {
    errors.amount = 'Saisir un montant ou un loyer';
    errors.rent = 'Saisir un montant ou un loyer';
  } else {
    if (amount) {
      const min = simulator.getFinancingAmountMin();
      const max = simulator.getFinancingAmountMax();
      if (!amount.match(new RegExp(formConfig.fields.amount.pattern))) {
        errors.amount = 'Le montant est incorrect';
      } else if (amount < min) {
        errors.amount = `Le montant ne peux pas être inférieur à ${min}€`;
      } else if (amount > max) {
        errors.amount = `Le montant ne peux pas être supérieur ${max}€`;
      }
    } else if (!rent.match(new RegExp(formConfig.fields.rent.pattern))) {
      errors.rent = 'Le loyer est incorrect';
    }
  }

  return errors;
};

class Simulation extends Component {
  constructor(props) {
    super(props);

    this.fields = formConfig.fields;

    this.renderField = this.renderField.bind(this);
    this.validate = this.validate.bind(this);
  }

  renderField(key) {
    const config = this.fields[key];
    return <Field key={key} {...config} />;
  }

  componentDidMount() {
    this.props.setTitle('Simulation');

    ApiClient.simulationParameters().then(({data}) => {
      this.props.setParameters(data);
    })
  }

  validate(values) {
    const { parameters } = this.props;
    const simulator = new FinancingCoeff(parameters.amount, parameters.rent, parameters.partMin, parameters.partMax);
    return formValidation(values, simulator);
  }

  onSubmit(values) {
    values.dateCreation = new Date();
    const data = {...values};

    if (data.rent && typeof data.rent === 'string') {
      data.rent = data.rent.replace(',', '.');
      data.rent = parseFloat(data.rent);
    }

    this.props.simulation(data);
    this.props.history.push(RouterHelper.uriOf('scoring'));
  }

  render() {
    return (
      <div>
        <Form onSubmit={this.onSubmit.bind(this)} validate={this.validate} noValidate>
          {this.renderField('type')}
          {this.renderField('periodicity')}
          {this.renderField('amount')}
          {this.renderField('rent')}
          <FormRow action>
            <Submit label='Envoyer' />
          </FormRow>
        </Form>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    parameters: state.app.parameters,
  };
}

export default connect(mapStateToProps, { setTitle, simulation, setParameters })(Simulation);
