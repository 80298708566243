import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import RouterHelper from '../../helpers/RouterHelper';
import { setTitle, simulation } from '../../actions';

class Home extends Component {
  componentDidMount() {
    this.props.setTitle('Accueil');
  }

  render() {
    const { user } = this.props;
console.log(user);
    return (

        <div>
          <h3 className="text-center">Bienvenue à <br/>{user.getCompany().getName()}</h3>
          <p className="text-center">{user.firstname} {user.lastname}</p>
          <p className="text-center">
            {RouterHelper.linkComponentOf(<Button content='Simulation' fluid size='big' />, 'simulation')}
          </p>
          <p className="text-center">
          {RouterHelper.linkComponentOf(<Button content='Demande de financement en ligne' onClick={() => this.props.simulation({})} fluid size='big' />, 'scoring_online')}
          </p>
          <p className="text-center">
            {RouterHelper.linkComponentOf(<Button content='Suivi des demandes' fluid size='big' />, 'listing')}
          </p>
        </div>

    );
  }
};

function mapStateToProps(state) {
  return {
    user: state.user.user
  };
}

export default connect(mapStateToProps, { setTitle, simulation })(Home);
