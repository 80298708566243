import React from 'react';
import RouterHelper from '../../helpers/RouterHelper';

export default ({location}) => {
  return (
    <div>
      <h3>Aucun résultat pour l'adresse <code>{location.pathname}</code></h3>
      <p>{RouterHelper.linkComponentOf('Accueil', 'home')}</p>
    </div>
  );
};
