import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal as ModalUI } from 'semantic-ui-react';

class Modal extends Component {
  renderModals() {
    if (this.props.modals) {
      return this.props.modals.map((config) => {
        const { id, modal } = config;
        const tmp = <ModalUI key={id} {...modal} open={true} />;

        return tmp;
      });
    }

    return <div>modals</div>;
  }

  render() {
    const modals = this.renderModals();

    return (
      <div>
        {modals}
      </div>
    );
  }
};

function mapStateToProps(state) {
  return { modals: state.modals };
}

export default connect(mapStateToProps)(Modal);
