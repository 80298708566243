import React from 'react';
import * as Form from './index';

export const Field = (props) => {
  const meta = props.meta || {};
  const { touched, error } = meta;
  const message = function(touched, error) {
    if (touched && error) {
      return (
        <div className="text-help">{ error }</div>
      );
    }
    return null;
  };

  function renderInput(props) {
    const { paragraph, ...rest } = props;
    switch(rest.type) {
      case 'file':
        return <Form.File {...rest.input} {...rest} />;
      case 'group':
        return <Form.Group {...rest} />;
      case 'radio':
        return renderRadio(rest);
      case 'checkbox':
        return <Form.Checkbox {...props} />;
      case 'textarea':
        return <Form.Textarea {...rest} />;
      default:
        return <Form.Input {...rest.input} {...rest} />;
    }
  }

  function renderRadio(props) {
    return props.options.map((option, index)=> {
      const p = {...props, ...option, data: props.value};
      if (props.layout && props.layout === 'button') {
        return <Form.ButtonRadio key={index} {...p} />;
      }
      return <Form.Radio key={index} {...p} />;
    });
  };

  return (
    <div className="form-row">
      {props.paragraph && <p>{props.paragraph}</p>}
      <Form.FormRow label={props.label} {...meta}>
        {renderInput(props)}
        {message(touched, error)}
      </Form.FormRow>
    </div>
  );
};
