import { SIGNIN, SIGNOUT } from '../actions/user';

const INITIAL_STATE = { user: null, isAuthenticated: false };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case SIGNOUT:
      return INITIAL_STATE;
    case SIGNIN:
      return {...state, user: action.payload, isAuthenticated: true};
    default:
      return state;
  }
}
