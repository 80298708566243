import React from 'react';
import {
  Radio as RadioUI,
  // Button
} from 'semantic-ui-react';

export const Group = (props) => {
  let options = {...props};
  const isChecked = options.value === options.data;
  delete options.meta;
  delete options.options;
console.log(props, options);
  return (
    <span style={{display: 'block'}}>
      <RadioUI {...options} checked={isChecked} />
    </span>
  );
};

/*
  <Button.Group>
    <Button>One</Button>
    <Button>Two</Button>
    <Button>Three</Button>
  </Button.Group>
*/
