import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeSidenav, signout, simulation } from '../actions/';
import ApiClient from '../services/ApiClient';

import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';

import RouterHelper from '../helpers/RouterHelper';

class Sidenav extends Component {
  render() {
    const { isAuthenticated } = this.props.user;

    return (
      <Drawer
        docked={false}
        width={280}
        open={this.props.isOpen}
        onRequestChange={this.props.closeSidenav}
        disableSwipeToOpen={true}
      >
        <MenuItem
          onTouchTap={this.props.closeSidenav}
          primaryText='Accueil'
          containerElement={RouterHelper.linkComponentOf('Accueil', 'home')}
        />
        { isAuthenticated &&
          <MenuItem
            onTouchTap={this.props.closeSidenav}
            primaryText='Simulation'
            containerElement={RouterHelper.linkComponentOf('Simulation', 'simulation')}
          />
        }
        { isAuthenticated &&
          <MenuItem
            onTouchTap={function() {
              this.props.simulation({});
              this.props.closeSidenav();
            }.bind(this)}
            primaryText='Demande de financement en ligne'
            containerElement={RouterHelper.linkComponentOf('Demande de financement en ligne', 'scoring_online')}
          />
        }
        { isAuthenticated &&
          <MenuItem
            onTouchTap={this.props.closeSidenav}
            primaryText='Suivi des demandes'
            containerElement={RouterHelper.linkComponentOf('Mes contrats', 'listing')}
          />
        }
        <Divider />
        { isAuthenticated &&
          <MenuItem onTouchTap={() => {
            ApiClient.logout();
            this.props.signout();
            this.props.closeSidenav();
          }}>Se déconnecter</MenuItem>
        }
        { !isAuthenticated &&
          <MenuItem
            onTouchTap={this.props.closeSidenav}
            primaryText='Nouveau partenaire'
            containerElement={RouterHelper.linkComponentOf('Nouveau partenaire', 'signup')}
          />
        }
        { !isAuthenticated &&
          <MenuItem
            onTouchTap={this.props.closeSidenav}
            primaryText='Mot de passe oublié'
            containerElement={RouterHelper.linkComponentOf('Mot de passe oublié', 'forgot_password')}
          />
        }
      </Drawer>
    );
  }
};

function mapStateToProps(state) {
  return {
    isOpen: state.app.sidenavOpened,
    user: state.user,
  };
}

export default connect(mapStateToProps, { closeSidenav, signout, simulation })(Sidenav);
