import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Button, Progress } from 'semantic-ui-react';
import axios from 'axios';

import { setTitle, createModal, closeModal } from '../../actions';
import { API_ENDPOINT } from '../../constants/config';
import { Form, FormRow, Field, Submit } from '../Form';
import ApiClient from '../../services/ApiClient';
import prettyBytes from '../../services/PrettyBytes';
import { formConfig, formValidation } from './Simulation';
import { FinancingCoeff } from '../../services/FinancingSimulatorManager';
import { setParameters } from '../../actions';

import ConfirmationOnline from './ConfirmationOnline';

const ProgressModal = function({percent}) {
  return (<div className="progress-modal">
    <Progress percent={percent} color='blue' progress>Envoi des fichiers en cours</Progress>
  </div>);
}

class ScoringOnline extends Component {

  constructor(props) {
    super(props);

    this.defaultFieldsOrder = ['siret_siren', 'customer'];
    this.state = {
      daNumber: '',
      files: [],
      isSend: false,
      sended: {}
    };

    this.form = {
      fields: Object.assign({
        time: {
          label: 'Durée',
          name: 'time',
          type: 'radio',
          layout: 'button',
          options: [{ label: '2 ans', value: 24 }, { label: '3 ans', value: 36 }, { label: '4 ans', value: 48 }, { label: '5 ans', value: 60 }]
        },
        siret_siren: {
          label: 'Numéro de SIRET / SIREN',
          name: 'siret_siren'
        },
        customer: {
          label: 'Nom de l\'entreprise client',
          name: 'customer'
        }
      }, formConfig.fields)
    };

    this.renderFiles = this.renderFiles.bind(this);
    this.addFile = this.addFile.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.validate = this.validate.bind(this);
    this.isFromSimulation = this.isFromSimulation.bind(this);
  }

  componentDidMount() {
    this.props.setTitle('Financement en ligne');

    ApiClient.simulationParameters().then(({data}) => {
      this.props.setParameters(data);
    })
  }

  validate(values) {
    const { siret_siren, customer, time } = values;
    let errors = {};

    if (!this.isFromSimulation() && !time) {
      errors.time = 'Choisissez une durée';
    }

    if (!siret_siren) {
      errors.siret_siren = 'Saisissez un numéro de SIRET ou SIREN';
    }

    if (!customer) {
      errors.customer = 'Saisir un nom';
    }


    if (!this.props.simulation || Object.keys(this.props.simulation).length === 0) {
      const { parameters } = this.props;
      const simulator = new FinancingCoeff(parameters.amount, parameters.rent, parameters.partMin, parameters.partMax);
      errors = Object.assign(errors, formValidation(values, simulator));
    }
    return errors;
  }

  onSubmit(values) {
    const dateCreation = new Date();
    values.dateCreation = dateCreation;
    const { simulation, scoring } = this.props;
    let formData = Object.assign({}, simulation, values);
    const { amount, siret_siren, customer, rent } = formData;
    const day = ('0' + dateCreation.getDate()).substr(-2);
    const month = ('0' + (dateCreation.getMonth() + 1)).substr(-2);
    const year = dateCreation.getFullYear();
    const date = `${day}/${month}/${year}`;
    let rentAmount;
    let rentTime;

    const sendingModal = {
      open: true,
      content: 'Votre demande est en cours d\'envoi',
      onClose: this.props.closeModal.bind(null, 'sending_success'),
    };

    this.props.createModal('sending_success', sendingModal);

    if (rent) {
      const rentTimeKeys = Object.keys(scoring.time);
      rentTime = rentTimeKeys[0];
      rentAmount = scoring.time[rentTime];
    } else if (scoring){
      Object.keys(scoring.time).forEach(duration => {
        if (scoring.time[duration]) {
          formData = { ...formData, time: parseInt(duration, 10) };
        }
      });
    }
    
    ApiClient.financingRequest(rent ? rentAmount : amount, date, siret_siren, customer).then(({data}) => {
      const daNumber = data;
      this.setState({daNumber});
      
      // Files handler
      const precall = new Promise((resolve, reject) => {
        if (this.state.files.length === 0) {
          resolve();

          return;
        }

        let formFile = new FormData();
        this.state.files.forEach((file, index) => {
          formFile.append(`attachment_${index}`, file);
        });

        const modal = {
          open: true,
          content: <ProgressModal percent="0"/>,
        };

        this.props.createModal('send_files', modal);

        axios.post(`${API_ENDPOINT}/api/files`, formFile, {
          onUploadProgress: progressEvent => {
            const progressValue = Math.round(progressEvent.loaded / progressEvent.total * 100);
            const modal = {
              open: true,
              content: <ProgressModal percent={progressValue} />,
            };

            this.props.createModal('send_files', modal);
          },
          headers: {
            'content-type': 'multipart/form-data',
          }
        }).then(({data}) => {
          this.props.closeModal('send_files');

          resolve(data.storage_unique_key);
        }).catch((error) => {
          this.props.closeModal('send_files');
          const modal = {
            open: true,
            content: 'Une erreur est survenue pendant le transfert',
            onClose: this.props.closeModal.bind(null, 'send_files_error'),
            actions: [
              { key: 'done', content: 'Fermer' },
            ]
          };

          this.props.closeModal('sending_success');
          this.props.createModal('send_files_error', modal);

          reject();
        });
      });

      precall.then((storage_unique_key) => {
        ApiClient.scoringFile(daNumber, formData, this.state.files.length, storage_unique_key, rentAmount, rentTime).then(() => {
          const modal = {
            open: true,
            content: 'Votre demande a été envoyée',
            onClose: this.props.closeModal.bind(null, 'scoring_success'),
            actions: [
              { key: 'done', content: 'Fermer' },
            ]
          };

          this.props.closeModal('sending_success');
          this.props.createModal('scoring_success', modal);
          this.setState({ isSend: true });
        }).catch(() => {
          const modal = {
            open: true,
            content: 'Une erreur est survenue',
            onClose: this.props.closeModal.bind(null, 'scoring_error'),
            actions: [
              { key: 'done', content: 'Fermer' },
            ]
          };

          this.props.closeModal('sending_success');
          this.props.createModal('scoring_error', modal);
        });
      });
    });
  }

  renderFiles() {
    if (this.state.files.length === 0) {
      return;
    }

    return (
      <List divided relaxed className="file-list">
        {this.state.files.map((file, index)=> {
          return (
            <List.Item key={index} >
              <List.Icon name='file outline' size='large' verticalAlign='middle' />
              <List.Content className="file-label">
                <List.Header as='a'>{file.name}</List.Header>
                <List.Description as='a'>{prettyBytes(file.size, { locale: 'fr' })}</List.Description>
              </List.Content>
              <List.Content className="file-actions">
                <Button onClick={this.removeFile.bind(this, index)} icon className="btn">
                  <List.Icon name='trash' />
                </Button>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    );
  }

  isFromSimulation() {
    return this.props.simulation && Object.keys(this.props.simulation).length > 0;
  }

  renderFields() {
    const fieldsOrder = !this.isFromSimulation() ? formConfig.order.filter(key => key !== 'rent').concat(['time'], this.defaultFieldsOrder) : this.defaultFieldsOrder;

    return fieldsOrder.map((key) => {
      const config = this.form.fields[key];

      return <Field key={key} {...config} />;
    });
  }

  addFile(file) {
    if (!file) {
      return;
    }
    const {files} = this.state;
    files.push(file);

    this.setState({ files });
  }

  removeFile(fileIndex) {
    const {files} = this.state;
    files.splice(fileIndex, 1);
    this.setState({ files });
  }

  render() {
    const {isSend} = this.state;
    return (
      <div>
        {isSend && <ConfirmationOnline />}
        {!isSend && <Form onSubmit={this.onSubmit.bind(this)} validate={this.validate}>
          {this.renderFields()}

          <Field
            button={{
              content: 'Ajouter des documents',
              fluid: true
            }}
            name="file"
            type="file"
            onChange={
              function (e) {
                this.addFile(e.target.files[0]);
              }.bind(this)
            }
          />
          {this.renderFiles()}
          <FormRow action>
            <Submit label="Lancer l'étude" />
          </FormRow>
        </Form>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    simulation: state.app.simulation,
    scoring: state.app.scoring,
    parameters: state.app.parameters
  };
}

export default connect(mapStateToProps, { setTitle, createModal, closeModal, setParameters })(ScoringOnline);
