export class FinancingCoeff {
  constructor(amount, rent, partMin, partMax) {
    this.amount = amount;
    this.rent = rent;
    this.partMin = partMin;
    this.partMax = partMax
  }

  getCoeff(amount, rentOrAmount, type, periodicity, during) {
    const isRent = rentOrAmount === 'rent';
    return this[isRent ? 'getCoeffRent' : 'getCoeffAmount'](amount, type, periodicity, during);
  }

  getRentIndex(amount, type, periodicity, during) {
    console.log(this.rent, type)
    const steps = this.rent[type][periodicity][during];
    let i = 0;

    while (i < steps.length && (steps[i] < amount) && (steps[i + 1] <= amount)) {
      i++;
    }

    return i;
  }

  rentIsInArray(amount, type, periodicity, during) {
    const i = this.getRentIndex(amount, type, periodicity, during);
    if (this.rent[type][periodicity][during][i] !== amount) {
      return null;
    }
    return this.partMin[i];
  }

  getCoeffRent(amount, type, periodicity, during) {
    const i = this.getRentIndex(amount, type, periodicity, during);
    this.rentIsInArray(amount, type, periodicity, during);
    return this.amount[type][periodicity][during][i];
  }

  getCoeffAmount(amount, type, periodicity, during) {
    const steps = this.partMax;
    let i = 0;

    while (i < steps.length && (steps[i] < amount)) {
      i++;
    }
    return this.amount[type][periodicity][during][i];
  }

  getFinancingAmountMin() {
    return this.partMin[0];
  }

  getFinancingAmountMax() {
    const tableLength = this.partMax.length;
    return this.partMax[tableLength - 1];
  }
}